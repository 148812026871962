import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";

export class SetStatus401RedirectLogin {
  // 停止401状态码跳转登录页面
  static readonly type = '[App] Stop401RedirectLogin '

  constructor(readonly status: boolean) { }
}



export interface AppStateModel {
  status401RedirectLogin: boolean;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    status401RedirectLogin: false
  }
})
export class AppState {

  @Selector()
  static getState(state: AppStateModel): AppStateModel {
    return AppState.getInstanceState(state);
  }

  private static getInstanceState(state: AppStateModel): AppStateModel {
    return {...state};
  }


  private static setInstanceState(state: AppStateModel): AppStateModel {
    return {...state};
  }

  @Action(SetStatus401RedirectLogin)
  loggedIn(
    {setState}: StateContext<AppStateModel>,
    {status}: SetStatus401RedirectLogin
  ) {
    setState(AppState.setInstanceState({status401RedirectLogin: status}));
  }
}
